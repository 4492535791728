Vue.component('additional-costs', {
    props: {
        translations: Object,
        getItemsUrl: String,
        updateItemUrl: String,
        deleteItemUrl: String,
        // modified: Boolean,
        // value: String,
        // default: String,
    },
    components: {
        'vue-multiselect': window.VueMultiselect.default
    },
    data: function () {
        return {
            deliveryProducts: [],
            arrangementProducts: [],
            productToAdd: '',
            productDeliveryToAdd: '',
            addedArrangementItems: [],
            addedDeliveryItems: [],
            keyCodeDigits: [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105],
            keyCodeFractals: [188, 190, 100],
            allowedCharactersSingle: [ ',' ],
            allowedCharactersDouble: [ '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',' ],
            costsLoading: false
        }
    },
    computed: {
        availableArrangementProducts() {

            let ids = this.addedArrangementItems.map(function(x){
                if(x.product !== null) {
                    return x.product.productID;
                }
            });

            return this.arrangementProducts.filter(i => !ids.includes(i.productID));
        },
        availableArrangementProductsGrouped() {
            const grouped = [];
            const categories = this.availableArrangementProducts;

            categories.forEach(function (category) {
                const categoryItem = {
                    group: category.title,
                    products: []
                }

                category.all_products.forEach(function (product) {
                    categoryItem.products.push({
                        variant: product.variant,
                        id: product.productID,
                    })
                })
                grouped.push(categoryItem);
            })

            return grouped;
        },
        availableDeliveryProducts() {

            let ids = this.addedDeliveryItems.map(function(x){
                if(x.product !== null) {
                    return x.product.productID;
                }
            });

            return this.deliveryProducts.filter(i => !ids.includes(i.productID));
        },
        availableDeliveryProductsGrouped() {
            const grouped = [];
            const categories = this.availableDeliveryProducts;

            categories.forEach(function (category) {
                const categoryItem = {
                    group: category.title,
                    products: []
                }

                category.all_products.forEach(function (product) {
                    categoryItem.products.push({
                        variant: product.variant,
                        id: product.productID,
                    })
                })
                grouped.push(categoryItem);
            })

            return grouped;
        },
        deliveryCosts() {
            let total = 0;
            this.addedDeliveryItems.forEach(function (item) {
                if (item.has_variable_price) total += Number(String(item.price).replace(',', '.'));
                else total += item.product.price * Number(item.amount);
            });
            return total;
        },
        arrangementCosts() {
            let total = 0;
            this.addedArrangementItems.forEach(function (item) {
                if (item.has_variable_price) total += Number(String(item.price).replace(',', '.'));
                else total += item.product.price * Number(item.amount);
            });
            return total;
        }
    },
    methods: {
        trans(key) {
            return this.translations[key].replace(/\\n/g, "\n");
        },
        addProduct(productToAdd = false) {
            const self = this;
            if (productToAdd !== false) {
                self.productToAdd = productToAdd.id;
            }
            API.PUT(self.updateItemUrl, {
                productID: self.productToAdd,
                amount: 1
            }).then(response => {
                self.productToAdd = '';
                self.addedArrangementItems.push(response.data);
                self.$emit('change-arrangement', {modified: true, value: self.arrangementCosts})
            });
        },
        addDeliveryProduct(productDeliveryToAdd = false) {
            const self = this;
            if (productDeliveryToAdd !== false) {
                self.productDeliveryToAdd = productDeliveryToAdd.id;
            }
            API.PUT(self.updateItemUrl, {
                productID: self.productDeliveryToAdd,
                amount: 1
            }).then(response => {
                self.productDeliveryToAdd = '';
                self.addedDeliveryItems.push(response.data);
                self.$emit('change-delivery', {modified: true, value: self.deliveryCosts})
            });
        },
        onProductQuantityKeyDown: function (item, event) {
            const product = item.product;
            let keyCode = event.keyCode;
            let inputValue = event.target.value;
            let inputValueContainsFractal = (inputValue.length > 0 && (inputValue.indexOf('.') >= 0 || inputValue.indexOf(',') >= 0));

            let isDigit = (this.keyCodeDigits.indexOf(keyCode) >= 0);
            let isFractal = (this.keyCodeFractals.indexOf(keyCode) >= 0);

            let isFractalAllowed = (product.is_divisible || false);
            if (isFractalAllowed && (inputValue.length === 0 || inputValueContainsFractal)) {
                isFractalAllowed = false;
            }

            if (this.isShiftKey(event)) {
                event.preventDefault();
            } else if (!this.isBackspaceKey(event) && !this.isPasteEvent(event)) {
                if (isFractal && !isFractalAllowed) {
                    event.preventDefault();
                } else if (!isFractal && !isDigit) {
                    event.preventDefault();
                }
            }
        },
        onProductQuantityKeyUp: function (item, event) {
            const self = this;
            const product = item.product;

            let isFractalAllowed = (product.is_divisible || false);

            if (!isFractalAllowed) {
                item.amount = item.amount.replace(/[^0-9]+/g, "");
            } else {
                item.amount = item.amount.replace(/,/g, '.');
                item.amount = item.amount.replace(/[^0-9\.]+/g, "");
            }

            if (!isFractalAllowed && item.amount < 1 && item.amount > 0) {
                item.amount = 1;
            } else if (isFractalAllowed && item.amount < 0) {
                item.amount = 1;
            }

            if (self.timeout) clearTimeout(self.timeout);
            this.timeout = setTimeout(() => {
                API.PUT(self.updateItemUrl, {
                    productID: item.productID,
                    amount: item.amount
                }).then(response => {
                    if (item.group === 'delivery')
                        self.$emit('change-delivery', {modified: true, value: self.deliveryCosts});
                    else if (item.group === 'arrangement')
                        self.$emit('change-arrangement', {modified: true, value: self.arrangementCosts});
                });
            }, 500);
        },
        updateProductQuantity: function (item) {
            const self = this;
            const product = item.product;

            let isFractalAllowed = (product.is_divisible || false);

            if (!isFractalAllowed) {
                item.amount = item.amount.replace(/[^0-9]+/g, "");
            } else {
                item.amount = item.amount.replace(/,/g, '.');
                item.amount = item.amount.replace(/[^0-9\.]+/g, "");
            }

            if (!isFractalAllowed && item.amount < 1 && item.amount > 0) {
                item.amount = 1;
            } else if (isFractalAllowed && item.amount < 0) {
                item.amount = 1;
            }

            API.PUT(self.updateItemUrl, {
                productID: item.productID,
                amount: item.amount
            }).then(response => {
                if (item.group === 'delivery')
                    self.$emit('change-delivery', {modified: true, value: self.deliveryCosts});
                else if (item.group === 'arrangement')
                    self.$emit('change-arrangement', {modified: true, value: self.arrangementCosts});
            });
        },
        deleteItem: function (index) {
            const self = this;

            if (confirm(self.trans('quotation.additional-costs.delete.confirmation')) !== true) {
                return false;
            }

            API.DELETE(self.deleteItemUrl, {productID: this.addedArrangementItems[index].productID})
                .then(response => {
                    this.addedArrangementItems.splice(index, 1);
                    self.$emit('change-arrangement', {modified: true, value: self.arrangementCosts});
                });
        },
        deleteDeliveryItem: function (index) {
            const self = this;

            if (confirm(self.trans('quotation.additional-costs.delete.confirmation')) !== true) {
                return false;
            }

            API.DELETE(self.deleteItemUrl, {productID: this.addedDeliveryItems[index].productID})
                .then(response => {
                    this.addedDeliveryItems.splice(index, 1);
                    self.$emit('change-delivery', {modified: true, value: self.deliveryCosts});
                });
        },
        isPasteEvent: function (event) {
            return ((event.ctrlKey || event.metaKey) && event.keyCode === 86);
        },
        isShiftKey: function (event) {
            return (event.shiftKey)
        },
        isBackspaceKey: function (event) {
            return (event.keyCode === 8);
        },
        isArrowKey: function (event) {
            return (event.keyCode === 37 || event.keyCode === 39);
        },
        isTabKey: function (event) {
            return (event.keyCode === 9)
        },
        minLength(value, min) {
            if (isNaN(value) || value < min) {
                return min;
            }

            return value;
        },
        inputWidthAsCh(item) {
            return this.minLength((item.price != null ? item.price.length + 0.5 : 0), 3.5) + 'ch';
        },
        stripInputValue(item) {
            let regexp = new RegExp('[^' + this.allowedCharactersSingle.concat(this.allowedCharactersDouble).join('') + ']', 'gi');
            item.price = String(item.price).replace(regexp, '');
        },
        onChange(item) {
            const self = this;
            self.stripInputValue(item);
            this.$emit('is-loading', true);
            API.PUT(self.updateItemUrl, {
                productID: item.productID,
                price: item.price
            }).then(response => {
                if (item.group === 'delivery')
                    self.$emit('change-delivery', {modified: true, value: self.deliveryCosts});
                else if (item.group === 'arrangement')
                    self.$emit('change-arrangement', {modified: true, value: self.arrangementCosts});
            });
        },
        onValueKeyUp(item, event) {
            const self = this;

            if (self.timeout) clearTimeout(self.timeout);
            this.timeout = setTimeout(() => {
                self.stripInputValue(item);
                this.$emit('is-loading', true);
                API.PUT(self.updateItemUrl, {
                    productID: item.productID,
                    price: item.price
                }).then(response => {
                    if (item.group === 'delivery')
                        self.$emit('change-delivery', {modified: true, value: self.deliveryCosts});
                    else if (item.group === 'arrangement')
                        self.$emit('change-arrangement', {modified: true, value: self.arrangementCosts});
                });
            }, 500);
        },
        onValueKeyDown(item, event) {
            if (this.isShiftKey(event)) {
                event.preventDefault();
                return false;
            } else if (!this.isBackspaceKey(event) && !this.isPasteEvent(event) && !this.isArrowKey(event)) {

                // Prevent if:
                // - Character is not allowed at all
                if (this.allowedCharactersSingle.concat(this.allowedCharactersDouble).indexOf(event.key) < 0) {
                    event.preventDefault();
                    return false;
                }
                // - Character is not allowed double
                if (this.allowedCharactersSingle.indexOf(event.key) >= 0 && event.target.value.indexOf(event.key) >= 0) {
                    event.preventDefault();
                    return false;
                }
            }

            this.stripInputValue(item);
            // if (item.group === 'delivery')
            //     this.$emit('change-delivery', {modified: true, value: this.deliveryCosts});
            // else if (item.group === 'arrangement')
            //     this.$emit('change-arrangement', {modified: true, value: this.arrangementCosts});
        },
    },
    mounted() {
        this.costsLoading = true;
        this.$emit('is-loading', true);
        API.GET(this.getItemsUrl).then(response => {
            this.addedDeliveryItems = response.data.addedDeliveryItems;
            this.addedArrangementItems = response.data.addedArrangementItems;
            this.arrangementProducts = response.data.arrangementProducts;
            this.deliveryProducts = response.data.deliveryProducts;
            this.costsLoading = false;
        });
    },
    destroyed() {
    },
    template:
        '<div>' +
        '   <h4 class="shoppingcart-additional-costs__title">{{ trans("checkout.price.delivery.label") }}</h4>\n' +
        '   <div class="select-wrapper">' +
        '       <vue-multiselect class="multiselect" :options="availableDeliveryProductsGrouped" ' +
        '           :multiple="false" group-values="products" group-label="group"' +
        '           :group-select="false" :close-on-select="true" ' +
        '           placeholder="Selecteer een product om het toe te voegen aan de offerte ..." ' +
        '           selectLabel="Druk enter om te selecteren"' +
        '           selectedLabel="Geselecteerd"' +
        '           :loading="costsLoading"' +
        '           track-by="id" label="variant" @select="addDeliveryProduct">' +
        '               <span slot="noResult">Geen producten gevonden</span>' +
        '               <span slot="noOptions">Geen producten gevonden</span>' +
        '       </vue-multiselect>' +
        '   </div>' +
        '   <ul class="shoppingcart-list__items">' +
        '       <li v-for="(item, index) in addedDeliveryItems" class="shoppingcart-list__item">' +
        '           <div class="shoppingcart-product" v-if="item.product !== null">' +
        '               <div class="shoppingcart-product__content">' +
        '                   <div class="shoppingcart-product__details">' +
        '                       {{ item.product.variant }}' +
        '                   </div>' +
        '               </div>' +
        '               <div class="shoppingcart-product__controls"' +
        '                   :class="{ \'shoppingcart-product__controls--variable-price\': item.has_variable_price }">' +
        '                   <template v-if="item.has_variable_price">' +
        '                       <div>' +
        '                           <label>' +
        '                               <span>€</span>' +
        '                               <input type="text" ref="input"' +
        '                               v-model="item.price" ' +
        '                               :style="{boxSizing: \'content-box\', width: inputWidthAsCh(item) }" ' +
        '                               @change="onChange(item)" ' +
        '                               @keyup="onValueKeyUp(item, $event)" ' +
        '                               @keydown="onValueKeyDown(item, $event)"/>' +
        '                           </label>' +
        '                       </div>' +
        '                   </template>' +
        '                   <template v-else>' +
        '                       <div>' +
        '                           <span class="shoppingcart-product__price">' +
        '                               {{ item.product.price | priceFormat }}' +
        '                           </span> x' +
        '                           <input type="text" class="shoppingcart-product__quantity" v-digitsonly' +
        '                               @keydown="onProductQuantityKeyDown(item, $event)"' +
        '                               @keyup="onProductQuantityKeyUp(item, $event)"' +
        '                               @change="updateProductQuantity(item)"' +
        '                               v-model="item.amount"/>' +
        '                       </div>' +
        '                       <span class="shoppingcart-product__price">' +
        '                           <span>{{ item.product.price * item.amount | priceFormat }}</span>' +
        '                       </span>' +
        '                   </template>' +
        '                   <a href="#" v-if="item.deletable" class="shoppingcart-product__delete" @click.prevent="deleteDeliveryItem(index)">' +
        '                       <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 1792 1792" class=""><path d="M704 1376V672q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0V672q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0V672q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zM672 384h448l-48-117q-7-9-17-11H738q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5H480q-66 0-113-58.5T320 1464V512h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"/></svg>' +
        '                       </a>' +
        '               </div>' +
        '           </div>' +
        '           <div class="shoppingcart-product" v-else>' +
        '               <div class="shoppingcart-product__content">' +
        '                   <div class="shoppingcart-product__details">' +
        '                       <strong>Kon product {{ item.productID }} niet laden; mogelijk is het gedeactiveerd in Exact</strong>' +
        '                   </div>' +
        '               </div>' +
        '               <div class="shoppingcart-product__controls">' +
        '                   <div>' +
        '                       <input type="text" class="shoppingcart-product__quantity" v-digitsonly' +
        '                           @keydown="onProductQuantityKeyDown(item, $event)"' +
        '                           @keyup="onProductQuantityKeyUp(item, $event)"' +
        '                           @change="updateProductQuantity(item)"' +
        '                           v-model="item.amount"/>' +
        '                   </div>' +
        '                   <span class="shoppingcart-product__price"></span>' +
        '                   <a href="#" class="shoppingcart-product__delete" @click.prevent="deleteItem(index)">' +
        '                       <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 1792 1792" class=""><path d="M704 1376V672q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0V672q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0V672q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zM672 384h448l-48-117q-7-9-17-11H738q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5H480q-66 0-113-58.5T320 1464V512h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"/></svg>' +
        '                       </a>' +
        '               </div>' +
        '           </div>' +
        '       </li>' +
        '   </ul>' +
        '   <h4 class="shoppingcart-additional-costs__title">{{ trans("checkout.price.arrangement.label") }}</h4>\n' +
        '   <div class="select-wrapper">' +
        '       <vue-multiselect class="multiselect" :options="availableArrangementProductsGrouped" ' +
        '           :multiple="false" group-values="products" group-label="group"' +
        '           :group-select="false" :close-on-select="true" ' +
        '           placeholder="Selecteer een product om het toe te voegen aan de offerte ..." ' +
        '           selectLabel="Druk enter om te selecteren"' +
        '           selectedLabel="Geselecteerd"' +
        '           :loading="costsLoading"' +
        '           track-by="id" label="variant" @select="addProduct">' +
        '               <span slot="noResult">Geen producten gevonden</span>' +
        '               <span slot="noOptions">Geen producten gevonden</span>' +
        '       </vue-multiselect>' +
        '   </div>' +
        '   <ul class="shoppingcart-list__items">' +
        '       <li v-for="(item, index) in addedArrangementItems" class="shoppingcart-list__item">' +
        '           <div class="shoppingcart-product" v-if="item.product !== null">' +
        '               <div class="shoppingcart-product__content">' +
        '                   <div class="shoppingcart-product__details">' +
        '                       {{ item.product.variant }}' +
        '                   </div>' +
        '               </div>' +
        '               <div class="shoppingcart-product__controls">' +
        '                   <div>' +
        '                       <span class="shoppingcart-product__price">' +
        '                           {{ item.product.price | priceFormat }}' +
        '                       </span> x' +
        '                       <input type="text" class="shoppingcart-product__quantity" v-digitsonly' +
        '                           @keydown="onProductQuantityKeyDown(item, $event)"' +
        '                           @keyup="onProductQuantityKeyUp(item, $event)"' +
        '                           @change="updateProductQuantity(item)"' +
        '                           v-model="item.amount"/>' +
        '                   </div>' +
        '                   <span class="shoppingcart-product__price">' +
        '                       <span>{{ item.product.price * item.amount | priceFormat }}</span>' +
        '                   </span>' +
        '                   <a href="#" class="shoppingcart-product__delete" @click.prevent="deleteItem(index)">' +
        '                       <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 1792 1792" class=""><path d="M704 1376V672q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0V672q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0V672q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zM672 384h448l-48-117q-7-9-17-11H738q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5H480q-66 0-113-58.5T320 1464V512h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"/></svg>' +
        '                       </a>' +
        '               </div>' +
        '           </div>' +
        '           <div class="shoppingcart-product" v-else>' +
        '               <div class="shoppingcart-product__content">' +
        '                   <div class="shoppingcart-product__details">' +
        '                       <strong>Kon product {{ item.productID }} niet laden; mogelijk is het gedeactiveerd in Exact</strong>' +
        '                   </div>' +
        '               </div>' +
        '               <div class="shoppingcart-product__controls">' +
        '                   <div>' +
        '                       <input type="text" class="shoppingcart-product__quantity" v-digitsonly' +
        '                           @keydown="onProductQuantityKeyDown(item, $event)"' +
        '                           @keyup="onProductQuantityKeyUp(item, $event)"' +
        '                           @change="updateProductQuantity(item)"' +
        '                           v-model="item.amount"/>' +
        '                   </div>' +
        '                   <span class="shoppingcart-product__price"></span>' +
        '                   <a href="#" class="shoppingcart-product__delete" @click.prevent="deleteItem(index)">' +
        '                       <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 1792 1792" class=""><path d="M704 1376V672q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0V672q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0V672q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zM672 384h448l-48-117q-7-9-17-11H738q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5H480q-66 0-113-58.5T320 1464V512h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"/></svg>' +
        '                       </a>' +
        '               </div>' +
        '           </div>' +
        '       </li>' +
        '   </ul>' +
        '</div>'
});
